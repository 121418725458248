import React from "react";
import styled from "styled-components";
import Image1 from "./assets/images/image1.png";
import Image2 from "./assets/images/image2.png";
import Image3 from "./assets/images/image3.png";
import SideImage from "../../assets/Login-Signup/leaf-tomato1.webp";
import SideImage2 from "../../assets/favorites/leaf.png";
import SideImage3 from "../../assets/Login-Signup/leaf-tomato6.webp";
import { Element } from "react-scroll";
import { useNavigate } from "react-router";
import { useState } from "react";
import BackdropWrapper from "../../components/modals/BackdropWrapper";
import NewletterModal from "../../components/modals/NewletterModal";
import { trackEvent } from "./utils/gtag";

export default function Features() {
  const [backdrop, setBackdrop] = useState(false);
  const navigate = useNavigate();

  const backdropHandler = () => {
    setBackdrop((prev) => !prev);
  };

  
    const handleEvent = (action, label) =>{
        trackEvent(action, 'Feature Interaction', label)
    
     }

  return (
    <Element name="features">
      <StyledFeatures>
        <BackdropWrapper
          handleBackdrop={backdropHandler}
          smallSize={true}
          element={
            <NewletterModal
              title={"Subscribe to Newsletter"}
              backdropHandler={backdropHandler}
              sendgridListId={"44a7af7c-4a02-4e14-bf62-2115bc51ba5b"}
            />
          }
          open={backdrop}
        />

        <div className="feature_container">
          <div className="feature">
            <div className="feature_content">
              <div className="image">
                <img src={Image1} alt="" />
              </div>

              <div className="content">
                <h3>Transform Recipes Effortlessly</h3>
                <p>
                  With BreakBread, creators can easily digitize their
                  handwritten or typed recipes. Just snap a picture, and our
                  advanced tools convert it into an interactive, shareable
                  format. It’s never been this easy to modernize your cookbook.
                </p>
                <button onClick={() => 
               { 
               
                navigate("/favorites?convertOpen=1");
                handleEvent('Creators-Landing2', 'Digitize Recipe');
                }
                }>
                  Digitize Now!
                </button>
                <p>
                  Discover endless recipes, culinary inspiration with your
                  digital cookbook.
                </p>
              </div>
            </div>

            <div className="side_image">
              <img src={SideImage} alt="" />
            </div>
          </div>

          <div className="feature2">
            <div className="feature_content">
              <div className="content">
                <h3>Your Digital Test Kitchen – Perfect Every Recipe</h3>
                <p>
                  Break Bread is more than a platform – it’s your AI-powered
                  culinary notebook. Whether you're crafting fresh recipes or
                  perfecting a family favorite, our tools help you streamline
                  the creative process. Turn your inspiration into
                  masterpiece-worthy recipes – faster and smarter than ever.
                </p>
                <button
                  onClick={() => {
                   
                    navigate("/ai-recipes");
                    handleEvent('Creators-Landing2', 'Start Perfecting Recipes');
                    }}
                  className="secondary-btn"
                >
                  Start Perfecting Recipes
                </button>
                <p>Digitize Your Test Kitchen Today!</p>
              </div>

              <div className="image">
                <img src={Image2} alt="" />
              </div>
            </div>

            <div className="side_image">
              <img src={SideImage2} alt="" />
            </div>
          </div>

          <div className="feature3">
            <div className="feature_content">
              <div className="image">
                <img src={Image3} alt="" />
              </div>

              <div className="content">
                <h3>Share & Grow Your Culinary Passion</h3>
                <p>
                  Showcase your unique recipes on your personalized BreakBread
                  page. Share your love for food, engage with fans, and
                  cultivate a community of like-minded food enthusiasts. Watch
                  your audience grow as they savor your creations.
                </p>
                <button onClick={()=> {
                 
                  backdropHandler();
                  handleEvent('Creators-Landing2', 'Join thousands of creators');
                  
                }}>
                  Join thousands of creators
                </button>
                <p>Get early access to break bread</p>
              </div>
            </div>

            <div className="side_image">
              <img src={SideImage3} alt="" />
            </div>
          </div>
        </div>
      </StyledFeatures>
    </Element>
  );
}

const StyledFeatures = styled.div`
  width: 90%;
  /* height: var(--section-height); */
  margin: 150px auto var(--section-margin) auto;
  position: relative;
  scroll-behavior: smooth;
  .feature_container {
    .feature {
      .feature_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;
        .content {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-basis: 45%;
          gap: 1rem;
          flex-direction: column;
          p {
            max-width: 80ch;
          }
          /* button {
            background-color: var(--primary-color);
            border-radius: var(--l-radius);
            &:hover {
              background-color: var(--primary-color-light);
            }
          } */
        }

        .image {
          flex-basis: 45%;
          width: 90%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 90%;
            height: auto;
            transform: rotate(10deg);
          }
        }
      }

      .side_image {
        height: 50px;
        img {
          position: absolute;
          right: -6%;
          top: 30%;
          width: 80px;
          height: auto;
          transform: rotate(90deg);
        }
      }
    }

    .feature2 {
      .feature_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;
        padding-top: 10%;
        /* padding-left: 5%; */

        .content {
          flex-basis: 45%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 1rem;
          flex-direction: column;
          h3 {
            max-width: 25ch;
          }
          p {
            max-width: 80ch;
          }
          /* button {
            background-color: var(--secondary-color);
            border-radius: var(--l-radius);
            &:hover {
              background-color: var(--secondary-light);
            }
          } */
        }

        .image {
          flex-basis: 45%;
          width: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          img {
            width: 80%;
            height: auto;
          }
        }
      }

      .side_image {
        height: 50px;
        img {
          position: absolute;
          left: -6%;
          top: 65%;
          width: 60px;
          height: auto;
          transform: rotate(180deg);
        }
      }
    }

    .feature3 {
      .feature_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;
        padding-top: 10%;
        .content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-basis: 45%;
          gap: 1rem;
          flex-direction: column;
          h3 {
            max-width: 25ch;
          }
          p {
            max-width: 80ch;
            max-width: 80ch;
          }
          /* button {
            background-color: var(--primary-color);
            border-radius: var(--l-radius);
            &:hover {
              background-color: var(--primary-color-light);
            }
          } */
        }

        .image {
          flex-basis: 45%;
          width: 70%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          img {
            width: 70%;
            height: auto;
          }
        }
      }

      .side_image {
        height: 50px;
        img {
          position: absolute;
          right: -5.6%;
          top: 98%;
          width: 100px;
          height: auto;
          transform: rotate(90deg);
        }
      }
    }
  }

  @media (min-width: 640px) and (max-width: 750px) {
  .feature {
       margin-top: 400px;
      }
  }

  @media (max-width: 640px) {
    .feature_container {
      .feature {
        .feature_content {
          flex-direction: column;
          gap: 4rem;
          .content {
            justify-content: center;
            align-items: center;
            h3 {
              text-align: center;
            }
            p {
              text-align: center;
            }
          }
          .image {
         
          img {
            margin-top: 100px;
          }
        }
        }
        .side_image {
          img {
            top: 35%;
          }
        }
      }
      .feature3 {
        .feature_content {
          flex-direction: column;
          gap: 4rem;
          .content {
            justify-content: center;
            align-items: center;
            h3 {
              text-align: center;
            }
            p {
              text-align: center;
            }
          }
          .side_image {
          img {
            top: 94.8%;
            right: -8.2%;
          }
        }
        }
      }
      .feature2 {
        .feature_content {
          flex-direction: column-reverse;
          gap: 4rem;
          padding-left: 0%;
          .content {
            justify-content: center;
            align-items: center;
            h3 {
              text-align: center;
            }
            p {
              text-align: center;
            }
          }
        }
      }
    }
  }

  @media (min-width: 640px) and (max-width: 1024px) {
    .feature_container {
      .feature {
        .side_image {
          img {
            top: 30%;
          }
        }
      }
       .feature3 {
        .side_image {
           img {
            top: 94.8%;
            right: -8.2%;
          }
        }
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import InstacartIngredientBtn from "./InstacartIngredientBtn";
import CartIngredient from "./CartIngredient";
import { sortIngredientsAlphabeticallly } from "./cartfunction";

export default function ConfirmCartModal({ backdropHandler, checkedRecipes }) {
  const [checkedIngredients, setCheckedIngredients] = useState([]);

  function extractCheckedIngredients(checkedRecipes) {
    let finalIngreients = [];
    checkedRecipes.forEach((recipe) => {
      recipe.ingredients.data.forEach((ingredient) => {
        if (ingredient.checked) {
          const ingredientIndex = finalIngreients.findIndex(
            (value) =>
              value.nameClean?.toLowerCase() ===
              ingredient.nameClean?.toLowerCase()
          );
          console.log("ing index", ingredientIndex);
          if (ingredientIndex !== -1) {
            finalIngreients[ingredientIndex].measures.us.amount +=
              ingredient.measures.us.amount;
          } else {
            finalIngreients.push(ingredient);
          }
        }
      });
    });
    sortIngredientsAlphabeticallly(finalIngreients);
    return finalIngreients;
  }

  const ingredientsAmountHandler = (index, value) => {
    setCheckedIngredients((current) => {
      const updatedIngredients = [...current];
      updatedIngredients[index] = {
        ...updatedIngredients[index],
        measures: {
          ...updatedIngredients[index].measures,
          us: {
            ...updatedIngredients[index].measures.us,
            amount: updatedIngredients[index].measures.us.amount + value,
          },
        },
      };
      console.log("curr", updatedIngredients[index].measures.us.amount);
      return updatedIngredients;
    });
  };

  const ingredientCheckedHandler = (index, isChecked) => {
    setCheckedIngredients((current) => {
      const updatedIngredients = [...current];
      updatedIngredients[index].checked = isChecked;
      return updatedIngredients;
    });
  };


  useEffect(() => {
    setCheckedIngredients(extractCheckedIngredients(checkedRecipes));
  }, []);

  return (
    <>
      <StyledCartModal>
        <div className="heading">
          <h4>Confirm Cart</h4>
          <button className="icon" onClick={(e) => backdropHandler(false)}>
            {<CloseIcon htmlColor="#F79C16" fontSize="large" />}
          </button>
        </div>

        <div className="confirm-cart">
          <div className="main_container">
            {checkedIngredients?.map((ingredient, index) => (
              <CartIngredient
                ingredient={ingredient}
                index={index}
                handleCheckboxChange={ingredientCheckedHandler}
                ingredientsAmountHandler={ingredientsAmountHandler}
              />
            ))}
          </div>
          <InstacartIngredientBtn
            selectedItems={checkedIngredients}
            ingredientMeasures={"us"}
            checkedRecipes={checkedRecipes}
          />
        </div>
      </StyledCartModal>
    </>
  );
}

const StyledCartModal = styled.div`
  p {
    text-align: center;
    max-width: 35ch;
    margin: auto;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .confirm-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    .main_container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: space-between;
      padding-bottom: 2%;
    }
    .no-ingredients-message {
      text-align: center;
      color: #888;
      font-size: 1.2rem;
      margin-top: 2rem;
    }
  }

  @media (max-width: 640px) {
    .confirm-cart {
      .main_container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }
`;

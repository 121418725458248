import React, { useState } from "react";
import styled from "styled-components";
import BackdropWrapper from "../modals/BackdropWrapper";
import ConfirmCartModal from "./ConfirmCartModal";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alertSlice";

export default function CartSummary({ data }) {
  const [backdrop, setBackdrop] = useState(false);
  const mediaQuery = useMediaQuery("(min-width: 1025px)");
  const dispatch = useDispatch();

  let checkedRecipes = [];
  Object.values(data).forEach((dateData) => {
    const recipes = Object.values(dateData.items);
    if (dateData.checked) {
      checkedRecipes = [...checkedRecipes, ...recipes];
    } else {
      recipes.forEach((item) => item.checked && checkedRecipes.push(item));
    }
  });
  function backdropHandler() {
    if (checkedRecipes.length === 0) {
      dispatch(
        alertActions.setAlert({
          title: "No recipe selected",
          messageType: "warning",
        })
      );
    } else {
      setBackdrop((current) => !current);
    }
  }

  return (
    <>
      <BackdropWrapper
        handleBackdrop={backdropHandler}
        element={
          <ConfirmCartModal
            backdropHandler={backdropHandler}
            checkedRecipes={checkedRecipes}
          />
        }
        open={backdrop}
      />
      {mediaQuery ? (
        <StyledSummary>
          <h4>Order Summary</h4>
          <hr />
          <div className="main-container">
            {checkedRecipes.length > 0 ? (
              <ul>
                {checkedRecipes.map((recipe, index) => (
                  <li key={recipe.id}>
                    {index + 1}. {recipe.data.title}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="empty-message">No items selected.</p>
            )}
          </div>
          <hr />
          <button className="review-btn" onClick={backdropHandler}>
            <HiOutlineShoppingBag />
            Review Cart
          </button>
        </StyledSummary>
      ) : (
        <StyledMobileSummary>
          <div className="cart-items">
            <h5 className="heading">Cart Items:</h5>
            <h5>{checkedRecipes.length}</h5>
          </div>
          <button className="review-btn" onClick={backdropHandler}>
            <HiOutlineShoppingBag />
            Review Cart
          </button>
        </StyledMobileSummary>
      )}
    </>
  );
}

const StyledMobileSummary = styled.div`
  width: 90%;
  margin: auto;
  position: fixed;
  bottom: 8%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--background-color);
  border-radius: 50px;
  box-shadow: 2px 2px 12px var(--shadow);
  .cart-items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    padding: 0.8rem 0;
    .heading {
      color: var(--gray-color);
    }
    h5 {
      font-weight: 600;
    }
  }
  .review-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
  }
`;

const StyledSummary = styled.div`
  width: 100%;
  margin: auto;
  border: 1px solid var(--gray-color);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 15px;

  h4 {
    padding: 15px 0 0 0;
  }

  hr {
    width: 90%;
    margin: auto;
    border: 0;
    border-top: 1px solid var(--gray-color);
  }

  .main-container {
    min-height: 20vh;
    ul {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
      li {
        text-align: center;
      }
    }

    .empty-message {
      font-style: italic;
      color: var(--gray-color);
      text-align: center;
    }
  }
  .review-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
  }
`;

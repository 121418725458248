import React, { useRef } from "react";
import styled from "styled-components";
import logo from "../../assets/named-logo.svg";
import LoadingButton from "../../components/LoadingButton";
import { FaFacebook, FaYoutube, FaLinkedin, FaTiktok } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import moment from "moment";
import { Link } from "react-router-dom";
import firebaseApi from "../../utils/firebaseApi";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alertSlice";
import { useState } from "react";

export default function Footer() {
  const inputRef = useRef("");
  const [searchLoading, setSearchLoading] = useState(false);
  const year = moment(new Date()).format("yyyy");
  const dispatch = useDispatch();

  async function emailSubmitHandler(e) {
    e.preventDefault();
    setSearchLoading(true);
    try {
      const res = await firebaseApi.get(
        `/addNewsletterSubscriber?email=${inputRef.current.value}`
      );
      console.log("res", res.body);
      dispatch(
        alertActions.setAlert({
          title: "Subscription successful",
          messageType: "success",
        })
      );
    } catch (e) {
      console.log("newsletter subscription error", e);
      dispatch(
        alertActions.setAlert({
          title: "Subscription failed",
          messageType: "error",
        })
      );
    }
    setSearchLoading(false);
  }

  return (
    <StyledFooter>
      <div className="content">
        <div className="socials">
          <img src={logo} alt="" className="logo" />
          <div className="text">
            <h5>Ready to cook?</h5>
            <p>Subscribe to our newsletter!</p>
          </div>
          {/* <form onSubmit={emailSubmitHandler} className="search">
            <input
              required
              type="email"
              ref={inputRef}
              placeholder="Enter email"
            />
            <LoadingButton
              loading={searchLoading}
              type="submit"
              title="Subscribe"
              className="newsletter-subscribe-gtag"
            />
          </form> */}
          <div className="connect">
            <p>LET'S BE FRIENDS!</p>
            <div className="social-links">
              <a
                className="linkedIn-gtag"
                target="_blank"
                href="https://www.linkedin.com/company/break-bread-inc/"
              >
                <FaLinkedin />
              </a>
              <a
                className="facebook-gtag"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100086959720979"
              >
                <FaFacebook />
              </a>
              <a
                className="instagram-gtag"
                target="_blank"
                href="https://www.instagram.com/breakbreadai"
              >
                <AiFillInstagram />
              </a>
              <a
                className="tiktok-gtag"
                target="_blank"
                href="https://www.youtube.com/@breakbread8836"
              >
                <FaTiktok />
              </a>
              <a
                className="youtube-gtag"
                target="_blank"
                href="https://www.youtube.com/@breakbread8836"
              >
                <FaYoutube />
              </a>
            </div>
          </div>
        </div>
        {/* <div className="tabs">
          <h5>Tabs</h5>
          <div className="nav-tabs">
            <Link className="landing-login-gtag" to="/login">
              Login
            </Link>
            <Link
              className="landing-meal-planning-gtag"
              to="/dashboard/meal-planning"
            >
              Meal Planning
            </Link>
            <Link className="landing-explore-gtag" to="/dashboard/explore">
              Explore
            </Link>
            <Link
              className="landing-ai-recipes-gtag"
              to="/dashboard/ai-recipes"
            >
              AI Recipes
            </Link>
            <Link
              className="landing-meals-nutrition-gtag"
              to="/dashboard/meal-nutrition"
            >
              Meals Nutrition
            </Link>
          </div>
        </div> */}
        {/* <div className="recipes">
          <h5>Recipes</h5>
          <div className="nav-tabs">
            <Link className="footer-recipes-gtag" to="/dashboard/explore">
              Best
            </Link>
            <Link className="footer-recipes-gtag" to="/dashboard/explore">
              Diets
            </Link>
            <Link className="footer-recipes-gtag" to="/dashboard/explore">
              Cuisines
            </Link>
            <Link className="footer-recipes-gtag" to="/dashboard/explore">
              Under 45 minutes
            </Link>
            <Link className="footer-recipes-gtag" to="/dashboard/explore">
              Under 30 minutes
            </Link>
          </div>
        </div> */}
      </div>
      <div className="copyright">
        <p>© {year}, BreakBread</p>
        <p>|</p>
        <Link to="/terms-of-service">Terms of Service</Link>
        <p>|</p>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
    </StyledFooter>
  );
}

const StyledFooter = styled.section`
  background-color: var(--secondary-color);
  padding: 40px 8%;
  h5,
  li,
  p,
  a {
    color: var(--text-white-color);
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    flex-wrap: wrap;
    gap: 1.2rem;
    .socials {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 20px;
      .text {
        h5 {
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
      .logo {
        width: 100px;
      }
      form {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        input {
          border-radius: 50px;
        }
        /* button {
          background-color: var(--primary-color);
          border-radius: var(--l-radius);
          &:hover {
            background-color: var(--primary-color-light);
          }
        } */
      }
      .connect {
        display: flex;
        justify-content: center;
        align-items: center;
        /* align-items: flex-start; */
        flex-direction: column;
        gap: 20px;

        .social-links {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        p {
          text-align: center;
        }
        a {
          margin-right: 10px;
        }
        svg {
          width: 20px;
          height: 20px;
          color: white;
        }
      }
    }
    .tabs,
    .recipes {
      h5 {
        margin-bottom: 16px;
        text-align: center;
      }
      .nav-tabs {
        display: flex;
        flex-direction: column;
        gap: 6px;
        a {
          color: white;
        }
      }
    }
  }
  .copyright {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    a {
      text-decoration: underline;
    }
  }
`;

import React from "react";
import styled from "styled-components";
import CartIngredient from "./CartIngredient";
import { sortIngredientsAlphabeticallly } from "./cartfunction";

export default function CartIngredients({
  recipeId,
  recipe,
  headingId,
  toggleSelection,
  setCartData,
}) {
  let { ingredients } = recipe;
  const ingredientsAmountHandler = (index, value) => {
    const updatedIngredients = {
      ...ingredients,
      data: ingredients.data.map((ingredient, i) => {
        if (i === index) {
          return {
            ...ingredient,
            measures: {
              ...ingredient.measures,
              us: {
                ...ingredient.measures.us,
                amount: ingredient.measures.us.amount + value,
              },
            },
          };
        } else {
          return ingredient;
        }
      }),
    };

    setCartData((current) => {
      const updatedCart = {
        ...current,
        [headingId]: {
          ...current[headingId],
          items: {
            ...current[headingId].items,
            [recipe.id]: {
              ...current[headingId].items[recipe.id],
              ingredients: updatedIngredients,
            },
          },
        },
      };
      return updatedCart;
    });
  };

  const handleCheckboxChange = (e, index) => {
    const isChecked = e.target.checked;
    setCartData((prev) => {
      const newData = { ...prev };

      return toggleSelection({
        data: newData,
        selectionType: "ingredients",
        headingId,
        recipeId,
        ingredientIndex: index,
        isChecked,
      });
    });
  };

  return (
    <StyledIngredients>
      <h4>Ingredients</h4>
      <div className="main_container">
        {sortIngredientsAlphabeticallly(ingredients.data).map(
          (ingredient, index) => (
            <CartIngredient
              ingredient={ingredient}
              index={index}
              handleCheckboxChange={handleCheckboxChange}
              ingredientsAmountHandler={ingredientsAmountHandler}
            />
          )
        )}
      </div>
    </StyledIngredients>
  );
}

const StyledIngredients = styled.div`
  width: 95%;
  margin: 3% auto;
  h4 {
    text-align: center;
    margin-bottom: 1rem;
  }

  .main_container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-bottom: 2%;
  }

  @media (max-width: 640px) {
    .main_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

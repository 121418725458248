import React, { useState, useEffect } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import styled from "styled-components";
import CartIngredients from "./CartIngredients";
import BackdropWrapper from "../modals/BackdropWrapper";
import RecipeDetails from "../modals/RecipeDetails";
import { motion } from "framer-motion";
import ServingsBtns from "./ServingsBtns";
import { useMediaQuery } from "@mui/material";
import toggleSelection from "./cartfunction";

export default function CartRecipe({
  recipeId,
  recipe,
  headingId,
  setCartData,
}) {
  const [ingredientsOpen, setIngredientsOpen] = useState(false);
  const [recipeDetailsModal, setRecipeDetailsModal] = useState(false);
  const mobileMediaQuery = useMediaQuery("(max-width: 640px)");

  const handleRecipeDetailsClick = async () => {
    setRecipeDetailsModal((current) => !current);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    setCartData((prev) => {
      const newData = { ...prev };

      return toggleSelection({
        data: newData,
        selectionType: "recipe",
        headingId,
        recipeId,
        isChecked,
      });
    });
  };

  const toggleIngredients = () => {
    setIngredientsOpen((prev) => !prev);
  };

  function servingsChangeHandler(
    data,
    initialServings,
    newValue,
    ingredientMeasures
  ) {
    return data?.map((ingredient) => {
      let ingredientAmount = ingredient.measures?.us?.amount;
      ingredientAmount = parseFloat(
        ((ingredientAmount / initialServings) * newValue).toFixed(2)
      );
      return {
        ...ingredient,
        amount: ingredientAmount,
        measures: {
          [ingredientMeasures]: {
            ...ingredient.measures?.[ingredientMeasures],
            amount: ingredientAmount,
          },
        },
      };
    });
  }

  function servingsHandler(value) {
    setCartData((current) => {
      const newCart = { ...current };
      const newServings = recipe.data.savedServings + value;
      console.log("recipe ingredients", newServings, recipe.data.servings);
      if (newServings !== 0) {
        const updatedIngredients = servingsChangeHandler(
          recipe.data.extendedIngredients,
          recipe.data.servings,
          newServings,
          "us"
        );
        recipe.data.savedServings = newServings;
        recipe.ingredients.data = updatedIngredients;
        newCart[headingId].items[recipeId] = recipe;
      }
      return newCart;
    });
  }

  const recipeDetailsBackdrop = () => {
    setRecipeDetailsModal((current) => !current);
  };

  return (
    <>
      <BackdropWrapper
        open={recipeDetailsModal}
        handleBackdrop={recipeDetailsBackdrop}
        element={
          <RecipeDetails
            data={recipe.data}
            handleBackdrop={recipeDetailsBackdrop}
          />
        }
      />
      <MainRecipe>
        <div className="recipe_container">
          <div className="main_container">
            <div className="checkbox">
              <input
                type="checkbox"
                checked={recipe.checked}
                onChange={handleCheckboxChange}
              />
            </div>

            <div className="recipe-image">
              <img src={recipe.data?.image} alt={recipe.data?.title} />
            </div>

            <div className="recipe-info">
              <h5 className="recipe-title" onClick={handleRecipeDetailsClick}>
                {recipe.data.title.substring(0, 50) + "..."}
              </h5>
              {mobileMediaQuery ? (
                <div className="serving-btn  servings-mobile">
                  <p>Servings: </p>
                  <ServingsBtns
                    value={recipe.data.savedServings}
                    amountHandler={servingsHandler}
                  />
                </div>
              ) : (
                <p>Planned For: {recipe.data.plannedFor}</p>
              )}
            </div>
            {!mobileMediaQuery && (
              <div className="serving-btn">
                <ServingsBtns
                  value={recipe.data.savedServings}
                  amountHandler={servingsHandler}
                />
                <p>Servings</p>
              </div>
            )}
            <div className="toggle-icon" onClick={toggleIngredients}>
              {ingredientsOpen ? (
                <IoIosArrowUp className="icon" />
              ) : (
                <IoIosArrowDown className="icon" />
              )}
            </div>
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={
            ingredientsOpen
              ? { opacity: 1, height: "auto" }
              : { opacity: 0, height: 0 }
          }
          transition={{ duration: 0.3 }}
          style={{ overflow: "hidden" }}
        >
          <hr
            style={{
              margin: "0 2% 0 2%",
            }}
          />
          <CartIngredients
            recipeId={recipeId}
            recipe={recipe}
            headingId={headingId}
            toggleSelection={toggleSelection}
            setCartData={setCartData}
          />
        </motion.div>
      </MainRecipe>
    </>
  );
}

const MainRecipe = styled.div`
  width: 92%;
  margin: 3% auto 0 auto;
  border: 1px solid var(--gray-light-color);
  border-radius: 24px;
  &:hover {
    border: 1px solid var(--primary-color);
  }

  .recipe_container {
    .main_container {
      display: grid;
      grid-template-columns: 30px auto 3fr 40px;
      grid-template-rows: repeat(3, auto);
      align-items: center;
      gap: 2%;
      padding: 0.8rem 0.4rem;
      position: relative;

      .checkbox {
        grid-column: 1 / 2;
        grid-row: 1 / -1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .recipe-image {
        grid-column: 2 / 3;
        grid-row: 1 / -1;
        width: 100px;
        height: 100px;
        background-color: #ffffff;
        box-shadow: 0px 4px 10px var(--shadow);
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .recipe-info {
        grid-column: 3 / 4;
        grid-row: 1 / -1;
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        align-items: flex-start;
        .recipe-title {
          cursor: pointer;
          line-height: 2.5ex;
          height: 5ex;
          text-decoration: underline;
          overflow: hidden;
        }
        h5 {
          line-height: 1.3;
        }
        .servings-mobile {
          margin-top: 0.2rem;
          flex-direction: row;
          gap: 2%;
        }
      }

      .serving-btn {
        grid-column: 4 / 5;
        grid-row: 2 / 4;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        justify-self: end;
        p {
          font-weight: 500;
        }
      }

      .toggle-icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        .icon {
          width: 30px;
          height: 30px;
          cursor: pointer;
          @media (max-width: 500px) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 640px) {
    .recipe_container {
      .main_container {
        grid-template-columns: 20px auto 2fr 20px;
        grid-template-rows: auto auto auto;
        gap: 0.5rem;

        .checkbox {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }

        .recipe-image {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          width: 75px;
          height: 75px;
          img {
          }
        }

        .recipe-info {
          grid-column: 3 / 4;
          grid-row: 1 / 2;
        }

        .serving-btn {
          grid-column: 1 / -1;
          grid-row: 3 / 4;
          margin-top: 1rem;
          justify-self: center;
        }

        .toggle-icon {
          grid-column: 4 / 5;
          grid-row: 1 / 2;
          position: static;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;

import { createGlobalStyle } from "styled-components";
import Home from "./Home";
import Features from "./Features";
import Newsletter from "./Newsletter";
import Footer from "./Footer";


const GlobalStyle = createGlobalStyle`
:root{
  --secondary-color:#E94C4F;
  --secondary-color-dark:#cb4144;
  --secondary-color-light:#ee6261;
  --primary-color:#2DC65B;
  --primary-color-dark:#26ac4e;
  --primary-color-light:#50cc6d;

  body{
    overflow-x: hidden;
  }

  
  button:hover{
    background-color: var(--primary-color-dark);
  }

  .secondary-btn:hover{
    background-color: var(--secondary-color-dark);
  }
  
}
`;

export default function CreatorLanding() {
  return (
    <>
      <GlobalStyle/>
      <Home />
      <Features />
      {/* <Newsletter id="newsletter" /> */}
      <Footer />
     
    </>
  );
}





import React, { useState, useEffect } from "react";
import CartRecipe from "./CartRecipe.js";
import styled from "styled-components";

export default function CartRecipes({
  data,
  setCartData,
  headingId,
}) {
  const recipes = Object.entries(data)

  return (
    <StyledRecipes>
      {recipes.map(([recipeId, recipe], index) => {
        return (
        <CartRecipe
          key={index}
          recipeId={recipeId}
          recipe={recipe}
          headingId={headingId}
          setCartData={setCartData}
        />
      )})}
    </StyledRecipes>
  );
}

const StyledRecipes = styled.div`
  /* width: 90%; */
  display: flex;
  flex-direction: column;
  padding-bottom: 3%;
`;

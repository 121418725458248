import styled from "styled-components";
import { useMediaQuery, useTheme } from "@mui/material";
import logo from "../assets/named-logo.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { motion } from "framer-motion";

function LandingNavbar() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <StyledNav
      initial={{ y: "-100%" }}
      animate={{ y: "0%" }}
      transition={{ ease: "easeOut", duration: 0.5 }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 100 }}
        transition={{ ease: "easeOut", delay: 0.5 }}
        className="nav-logo"
      >
        <img alt="" src={logo} onClick={(e) => navigate("/landing-page")} />
      </motion.div>
      {/* {useMediaQuery("(min-width: 800px)") && (
        <div className="nav-tabs">
          <Link
            className="about-us-gtag"
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
          >
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 100 }}
              transition={{ ease: "easeOut", delay: 0.5 }}
            >
              About Us
            </motion.span>
          </Link>
          <Link
            className="our-ai-gtag"
            activeClass="active"
            to="ai"
            spy={true}
            smooth={true}
            offset={50}
            duration={1200}
          >
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 100 }}
              transition={{ ease: "easeOut", delay: 0.5 }}
            >
              Our AI
            </motion.span>
          </Link>
          <Link
            className="recipes-gtag"
            activeClass="active"
            to="recipes"
            spy={true}
            smooth={true}
            offset={50}
            duration={1400}
          >
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 100 }}
              transition={{ ease: "easeOut", delay: 0.5 }}
            >
              Recipes
            </motion.span>
          </Link>
          <Link
            className="faq-gtag"
            activeClass="active"
            to="faq"
            spy={true}
            smooth={true}
            offset={50}
            duration={1600}
          >
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 100 }}
              transition={{ ease: "easeOut", delay: 0.5 }}
            >
              FAQs
            </motion.span>
          </Link>
        </div>
      )} */}
      <div className="nav-buttons">
        <motion.button
        initial={{ opacity: 0 }}
        animate={{ opacity: 100 }}
        transition={{ ease: "easeOut", delay: 0.5 }}
          className="signup-gtag"
          onClick={(e) =>
            navigate("/dashboard/explore", {
              state: { from: location?.state?.from || "/dashboard" },
            })
          }
        >
          Go to App
        </motion.button>
      </div>
    </StyledNav>
  );
}

const StyledNav = styled(motion.div)`
position: sticky;
  height: 60px;
  z-index: 100;
  padding: 0% 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  background-color: white;
  box-shadow: 0 1px 8px var(--shadow);

  a,
  span {
    color: var(--text-light-color);
  }
  .nav-logo,
  .nav-tabs,
  .nav-buttons {
    display: flex;
    align-items: center;
  }
  .nav-logo {
    flex: 1 1 15%;
    img {
      height: 60px;
    }
  }
  .nav-tabs {
    flex: 1 1 70%;
    justify-content: space-evenly;
    span {
      margin-right: 20px;
      &:hover {
        color: var(--primary-color);
      }
    }
  }

  .nav-buttons {
    flex: 1 1 15%;
    justify-content: flex-end;
    gap: 14px;
    button {
      padding: 8px 12px;
      min-width: 100px;
    }
    div {
      margin-left: 40px;
    }
  }
  @media (max-width: 600px) {
    padding: 1% 2%;
    .nav-buttons {
      button {
        margin-left: 0;
      }
    }
  }
  @media (max-width: 1000px) {
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
  @media (min-width: 1200px) {
    .nav-logo {
      flex: 1 1 25%;
    }
  }
`;

export default LandingNavbar;

import React from "react";
import ServingsBtns from "./ServingsBtns";
import styled from "styled-components";

export default function CartIngredient({
  ingredient,
  index,
  handleCheckboxChange,
  ingredientsAmountHandler,
}) {
  return (
    <StyledIngredient className="ingredient" key={index}>
      <input
        type="checkbox"
        id={`ingredient-${index}`}
        checked={ingredient.checked}
        onChange={(e) => handleCheckboxChange(e, index)}
      />

      <div className="title">
        <label className="ingredients" htmlFor={`ingredient-${index}`}>
          {ingredient.nameClean.charAt(0).toUpperCase() +
            ingredient.nameClean.slice(1)}
        </label>
        <div className="amount">
          <ServingsBtns
            value={ingredient.measures.us.amount}
            unit={ingredient.measures.us.unitShort}
            id={index}
            amountHandler={ingredientsAmountHandler}
          />
        </div>
      </div>
    </StyledIngredient>
  );
}

const StyledIngredient = styled.div`
  width: calc(50% - 0.5rem);
  min-height: 84px;
  background-color: #fbfbfb;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;

  input {
    margin-right: 1rem;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;
    label {
      /* flex: 1 1 auto; */
      max-width: 15ch;
    }
    .amount {
      display: flex;
      justify-content: center;
      /* flex: 1 1 auto; */
    }
  }
  @media (max-width: 450px) {
    .title {
      .ingredients{
        max-width: 20ch;
        white-space: normal;
      }
    }
  }
  @media (max-width: 640px) {
    width: 90%;
  }
`;

import React, { useState } from "react";
import Logo from "./assets/images/logo.png";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-scroll";


export default function Navbar() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {["Home", "Features", "Contact"].map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <Link
                to={text.toLowerCase()}
                smooth={true}
                duration={500}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                <ListItemText primary={text} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <StyledNavbar>
      <div className="navbar">
        <img src={Logo} alt="" />

        {/* <div className="nav_links">
          <Link to="home" spy={true} smooth={true} duration={1600}>
            Home
          </Link>
          <Link
            to="features"
            spy={true}
            smooth={true}
            duration={1600}
          >
            Features
          </Link>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            duration={1600}
          >
            Contact
          </Link>
        </div> */}

        {/* <div className="mobile_navbar">
          <GiHamburgerMenu
            onClick={toggleDrawer(true)}
            style={{ width: "30px", height: "30px", cursor: "pointer" }}
          />
          <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
            {DrawerList}
          </Drawer>
        </div> */}
      </div>
    </StyledNavbar>
  );
}

const StyledNavbar = styled.div`
  .navbar {
    width: 65%;
    padding-left: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    img {
      width: 10%;
    }

    .nav_links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4rem;
      a {
        font-weight: 600;
        scroll-behavior: smooth;
        color: var(--text-light-color);
        cursor: pointer;
        &:hover {
          color: var(--primary-color);
        }
      }
    }

    .mobile_navbar {
      display: none;
    }
  }

  @media (max-width: 640px) {
    .navbar {
      width: 90%;
      img {
        width: 15%;
      }
      .mobile_navbar {
        display: block !important;
      }
      .nav_links {
        display: none !important;
      }
    }
  }

  @media (min-width: 640px) and (max-width: 1024px) {
    .navbar {
      img {
        width: 15%;
      }
    }
  }
`;

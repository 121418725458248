import CartRecipes from "./CartRecipes";
import styled from "styled-components";
import CartSummary from "./CartSummary.js";
import CartToEmail from "./CartToEmail.js";
import CartHeader from "./CartHeader.js";
import { useLocation, useNavigate } from "react-router";
import { RiDeleteBin6Line } from "react-icons/ri";
import { db } from "../../authentication/firebase.js";
import { deleteField, doc, writeBatch } from "firebase/firestore";
import LoadingButton from "../LoadingButton.js";
import { useState } from "react";
import { auth } from "../../authentication/firebase.js";
import { alertActions } from "../../store/alertSlice.js";
import { useDispatch } from "react-redux";
export default function Cart({ cartData, setCartData }) {
  const [deleteLoading, setDeleteLoading] = useState(false);

  const urlPath = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch()

  function anyRecipeChecked() {
    for (const dateData of Object.values(cartData)) {
      if (dateData.checked) {
        return true;
      }
      for (const recipe of Object.values(dateData.items)) {
        if (recipe.checked) {
          return true;
        }
      }
    }
    return false;
  }

  async function deleteRecipesHandler() {
    try {
      setDeleteLoading(true);
      const batch = writeBatch(db);

      Object.entries(cartData).forEach(([date, dateData]) => {
        const yy = date.split("-")[0];
        const mm = date.split("-")[1];
        const dd = date.split("-")[2];
        if (dateData.checked) {
          const dateRef = doc(
            db,
            `shoppingCart/${auth.currentUser.uid}/${yy}-${mm}`,
            dd
          );
          console.log("yy", yy, mm, dd, dateData);
          delete cartData[date];
          batch.delete(dateRef);
        } else {
          Object.entries(dateData.items).forEach(([recipeId, recipe]) => {
            if (recipe.checked) {
              const recipeRef = doc(
                db,
                `shoppingCart/${auth.currentUser.uid}/${yy}-${mm}`,
                dd
              );
              batch.update(recipeRef, {
                [recipe.data.plannedFor]: deleteField(),
              });
              delete cartData[date].items[recipeId];
            }
          });
        }
      });

      await batch.commit(); // Commit batch after processing all deletions
      dispatch(
        alertActions.setAlert({
          title: "Recipe's deleted successfully",
          messageType: "success",
        })
      );
    } catch (e) {
      dispatch(
        alertActions.setAlert({
          title: "Recipe's deletion failed",
          messageType: "error",
        })
      );
      console.log("Error while deleting cart recipe", e);
    } finally {
      setDeleteLoading(false);
    }
  }

  console.log("anyChecked", anyRecipeChecked());
  return (
    <StyledCart>
      <div className="header">
        <h3>{urlPath === "/cart" ? "My Cart" : "Cart History"}</h3>
        {urlPath === "/cart" && (
          <button className="secondary-btn" onClick={() => navigate("history")}>
            Cart History
          </button>
        )}
      </div>
      <div className="main_cart">
        <div className="my-cart">
          <div className="cart-heading">
            <h4>Recipes</h4>
            {urlPath === "/cart" && (
              <>
                <LoadingButton
                  loading={deleteLoading}
                  disabled={!anyRecipeChecked()}
                  onClick={deleteRecipesHandler}
                  className="delete-btn icon"
                  title={
                    <RiDeleteBin6Line
                      fontSize="large"
                      className={`delete-icon`}
                    />
                  }
                />
              </>
            )}
          </div>
          {Object.entries(cartData).map(([headingId, data], index) => (
            <>
              <CartHeader
                headingId={headingId}
                setCartData={setCartData}
                cartData={cartData}
              />
              <div className="divider" />
              <CartRecipes
                data={data.items}
                headingId={headingId}
                setCartData={setCartData}
              />
            </>
          ))}
        </div>

        <div className="cart-summary">
          <CartSummary data={cartData} />
          <CartToEmail className="email-container" />
        </div>
      </div>
    </StyledCart>
  );
}

const StyledCart = styled.div`
  width: 90%;
  margin: 0 auto var(--section-margin) auto;
  padding-bottom: 3rem;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3 {
    margin-bottom: var(--heading-margin-sm);
    margin-top: var(--heading-margin-sm);
  }

  .main_cart {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
    .my-cart {
      flex-basis: 58%;
      border: 1px solid gray;
      border-radius: 24px;
      height: 65vh;
      overflow-y: auto;
      gap: 1rem;
      position: relative;
      .cart-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        position: sticky;
        background-color: var(--background-color);
        top: 0;
        z-index: 100;
        border-bottom: 1px solid var(--gray-light-color);
        .delete-btn {
          color: red;
          .delete_icon {
            color: inherit;
            /* width: 20px;
            height: 20px; */
          }
          &:disabled {
            color: gray;
            .delete_icon {
              /* color: gray; */
            }
          }
        }
      }
      .divider {
        width: 95%;
        margin: auto;
        border-bottom: 1px solid var(--gray-light-color);
      }
    }

    .cart-summary {
      flex-basis: 38%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
    }
  }

  @media (max-width: 1025px) {
    width: 95%;
    .main_cart .my-cart {
      flex-basis: 100%;
    }
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

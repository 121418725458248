const toggleSelection = ({
  data,
  selectionType,
  headingId,
  recipeId,
  ingredientIndex,
  isChecked,
}) => {
  const newData = JSON.parse(JSON.stringify(data));

  if (newData) {
    if (selectionType === "heading") {
      if (newData[headingId]) {
        newData[headingId].checked = isChecked;
        newData[headingId].deleteable = isChecked;

        Object.keys(newData[headingId].items).forEach((recipeId) => {
          newData[headingId].items[recipeId].checked = isChecked;

          newData[headingId].items[recipeId].ingredients.data.forEach(
            (ingredient) => {
              ingredient.checked = isChecked;
            }
          );
        });
      }
    } else if (selectionType === "recipe") {
      if (newData[headingId]?.items[recipeId]) {
        newData[headingId].items[recipeId].checked = isChecked;
        newData[headingId].deleteable = isChecked;

        newData[headingId].items[recipeId].ingredients.data.forEach(
          (ingredient) => {
            ingredient.checked = isChecked;
          }
        );
        console.log("new data", newData[headingId].checked)
        if (Object.values(newData[headingId].items).every(recipe => recipe.checked)) {
          newData[headingId].checked = true
        } else if(Object.values(newData[headingId].items).find(recipe => !recipe.checked)){
          newData[headingId].checked = false
        }
      }
    } else if (selectionType === "ingredients") {
      if (
        newData[headingId]?.items[recipeId]?.ingredients.data[ingredientIndex]
      ) {
        newData[headingId].items[recipeId].ingredients.data[
          ingredientIndex
        ].checked = isChecked;
      }
    }
  }
  return newData;
};

export function sortIngredientsAlphabeticallly(ingredients) {
  return ingredients.sort((a, b) => {
    const nameA = a.nameClean.toLowerCase(); // Convert to lowercase to ensure the sort is case-insensitive
    const nameB = b.nameClean.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0; // nameA === nameB
  });
}

export default toggleSelection;

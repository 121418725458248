import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "styled-components";

export default function ServingsBtns({ value, unit = "",amountHandler }) {
  return (
    <StyledServings className="serving-buttons">
      <button className="minus-btn" onClick={() => amountHandler(-1)}>
        <RemoveIcon />
      </button>
        <span>
          {value} {unit}
        </span>
      <button className="add-btn" onClick={(e) => amountHandler(1)}>
        <AddIcon />
      </button>
    </StyledServings>
  );
}

const StyledServings = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 120px;
  height: 40px;
  border-radius: 48px;
  background-color: #fff;
  span {
    white-space: nowrap;
  }

  .add-btn,
  .minus-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    background-color: transparent;
    /* border: 1px solid var(--shadow); */
    border-radius: 4px;
    color: var(--secondary-dark-color);
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
    &:hover {
      background-color: transparent;
    }
  }
  @media (max-width: 640px) {
    /* width: 100px; */
    height: 30px;
  }
`;
